import { defineComponent, ref, onMounted, watch, reactive } from '@vue/composition-api';
import { dateFormat } from '@/util/dates';
import { SITE_URL } from '@/App.vue';
import { visibilityChanged } from '@/util/globalHelpers';
import { BlogRoutes } from '@/Blog/blog.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { useMeta } from '@/shared/composables/useMeta';
import qs from 'query-string';
import PagePagination from '@/components/PagePagination.vue';
import { useGetAllBlogArticlesQuery } from '@/generated-types/graphql.types';
import EventBus from '@/shared/services/eventBus';
export default defineComponent({
    components: { PagePagination },
    setup(_, context) {
        const { root } = context;
        const stories = ref([]);
        const page = ref(1);
        const pageSize = ref(10);
        const maxPage = ref(1);
        const getAllBlogArticlesQueryVariables = reactive({
            pagination: {
                page: page.value,
                size: pageSize.value
            }
        });
        const { onResult, refetch } = useGetAllBlogArticlesQuery(getAllBlogArticlesQueryVariables);
        onResult(result => {
            maxPage.value = Math.ceil(result.data.get_all_blog_articles.total / pageSize.value);
            const _stories = result.data.get_all_blog_articles.stories;
            stories.value = _stories.map((story) => {
                const data = JSON.parse(story.story);
                const title = data.name;
                return {
                    link: globalThis.$i18n.locale === 'nl'
                        ? data.full_slug
                        : data.translated_slugs[0].path || data.full_slug,
                    title: globalThis.$i18n.locale === 'nl'
                        ? title
                        : data.translated_slugs[0].name || title,
                    image: {
                        alt: data.content.thumbnail.alt,
                        src: data.content.thumbnail.filename
                    },
                    date: dateFormat(data.created_at, 'DD MMM YY')
                };
            });
            if (!_stories.length) {
                page.value = 1; // see page.value watcher, it will refetch the data
            }
            history.replaceState({}, null, page.value === 1 ? window.location.pathname : `?page=${page.value}`);
            useMeta({
                title: root.$i18n.t('meta.blog.title_tag'),
                description: root.$i18n.t('meta.blog.description_tag'),
                url: `${SITE_URL}/${BlogRoutes.BLOG}`,
                noIndex: false
            });
        });
        EventBus.$on('languageSelected', refetch);
        const getImageLink = (src, placeholder) => {
            // https://www.storyblok.com/docs/Guides/how-to-resize-images
            return `${src}/m/${root.$mq.phone ? 320 : 520}x0${placeholder ? '/filters:blur(150):quality(70)' : ''}`;
        };
        watch(() => page.value, () => {
            if (page.value) {
                getAllBlogArticlesQueryVariables.pagination.page = page.value;
                refetch(getAllBlogArticlesQueryVariables);
                root.$scrollTo('#stories', 500, {
                    offset: -200
                });
            }
        }, { immediate: true });
        onMounted(() => {
            const currentQueryParams = qs.parse(location.search, {
                arrayFormat: 'bracket'
            });
            page.value =
                currentQueryParams.page && Number(currentQueryParams.page) > 0
                    ? Number(currentQueryParams.page)
                    : 1;
        });
        return {
            maxPage,
            stories,
            page,
            getImageLink,
            visibilityChanged,
            useGetLocalizedPath
        };
    }
});
